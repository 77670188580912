import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Contact from './pages/contacts';
import Portfolio from './pages/portfolio';


function Links() {
  return (

<Router> 
	  <div>
        <Link to="/contacts">Contact</Link>
	</div>
	
	<div>
		<Link to="/portfolio">Portfolio</Link>
			
      </div>
		
		
		
	
	  
	  <Switch>
	  
	   <Route path="/contacts">
          <Contact />
        </Route>
		
		 <Route path="/portfolio">
          <Portfolio />
        </Route>
		
		
	  </Switch>
	  
	  
	 </Router>
	 
	 
); }

export default Links;