import logo from './logo.svg';

import './App.css';



function App() {
  return (
	
	
  
    <div className="App">
      <header className="App-header">
       Puhtila Development
      </header>
	  <p className="text">Under maintenance.</p>
	  
	  </div>
    
  );
}

export default App;
