import {Helmet} from 'react-helmet';


function Meta() {
  return (

<Helmet>

	<title>Puhtila Development</title>
	<link rel="icon" href="./icon.png" type="image/x-icon"/>
	<meta name="description" content="Your description" />

	</Helmet>
	
);}
	
export default Meta;